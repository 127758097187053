import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-5"},[_c('custom-snackbar',{ref:"snackbar"}),_c(VCardText,{staticClass:"pt-1"},[_c(VSnackbar,{attrs:{"color":_vm.snackbarDownloadColor,"fixed":"","right":"","top":"","elevation":"24"},model:{value:(_vm.snackbarDownload),callback:function ($$v) {_vm.snackbarDownload=$$v},expression:"snackbarDownload"}},[_c('h4',[_vm._v(_vm._s(_vm.snackDownloadTitle))]),_c('h5',[_vm._v(_vm._s(_vm.snackDownloadText))]),_c(VProgressLinear,{attrs:{"indeterminate":"","color":"white darken-2"}})],1),_c(VToolbar,{staticClass:"d-flex flex-row-reverse",attrs:{"flat":""}},[_c(VCol,{staticClass:"col-12 col-md-1 my-0 py-0 pr-0"},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"btn-block",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-printer ")])],1)]}}])},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.relatorioPDF()}}},[_c(VListItemTitle,[_vm._v("Exportar PDF")])],1),_c(VListItem,{on:{"click":function($event){return _vm.exportFile()}}},[_c(VListItemTitle,[_vm._v("Exportar Excel")])],1)],1)],1)],1)],1),_c(VRow,{staticClass:"mt-3"},[_c(VCol,{staticClass:"col-12 col-md-4 my-0 py-0"},[_c('EmpresasSelect',{on:{"input":_vm.fieldsSelected},model:{value:(_vm.empresasFilterValue),callback:function ($$v) {_vm.empresasFilterValue=$$v},expression:"empresasFilterValue"}})],1),_c(VCol,{staticClass:"col-12 col-md-4 my-0 py-0"},[_c('ProducoesSelect',{attrs:{"empresa":_vm.empresasFilterValue,"multiple":""},on:{"input":_vm.fieldsSelected},model:{value:(_vm.producoesFilterValue),callback:function ($$v) {_vm.producoesFilterValue=$$v},expression:"producoesFilterValue"}})],1),_c(VCol,{staticClass:"col-12 col-md-3 my-0 py-0"},[_c('ProdutoSelect',{attrs:{"empresa":_vm.empresasFilterValue,"producao":_vm.producoesFilterValue,"mostra-todos-label":"","disabled":!_vm.empresasFilterValue},on:{"input":_vm.fieldsSelected},model:{value:(_vm.produtosFilterValue),callback:function ($$v) {_vm.produtosFilterValue=$$v},expression:"produtosFilterValue"}})],1),_c(VCol,{staticClass:"col-12 col-xs-12 col-sm-12 col-lg-1 py-0"},[_c(VTooltip,{attrs:{"top":"","color":"warning","max-width":"139px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"v-btn--height-fix",attrs:{"max-width":"20px","outlined":"","block":"","color":"info","item-text":"nome","item-value":"id","disabled":_vm.disabledCampos},on:{"click":_vm.getData}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-cached ")])],1)]}}]),model:{value:(_vm.pendingSearch),callback:function ($$v) {_vm.pendingSearch=$$v},expression:"pendingSearch"}},[_c('span',[_vm._v("Busca pendente")])])],1)],1),_c(VRow,[_c(VCol,[_c(VDataTable,{attrs:{"dense":"","sort-by":"produto_id","options":_vm.options,"headers":_vm.headers,"server-items-length":_vm.totalItems,"items":_vm.data,"items-per-page":_vm.$dataTableConfig.getItemsPerPage(),"footer-props":_vm.$dataTableConfig.getFooterProps(),"loading-text":_vm.$dataTableConfig.getLoadText(),"loading":_vm.loading,"page":_vm.page,"page-count":_vm.pageCount},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"item.quantidade_agricola",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin"},[_vm._v(_vm._s(_vm.formatNumber(item.quantidade_agricola, 2)))])]}},{key:"item.quantidade_sapiens",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin"},[_vm._v(_vm._s(_vm.formatNumber(item.quantidade_sapiens, 2)))])]}},{key:"no-data",fn:function(){return [_c(VSpacer),(_vm.errorLoad)?_c('div',[_c(VRow,{staticClass:"justify-center mt-1"},[_c(VCol,{staticClass:"col-lg-6"},[_c('custom-alert',{attrs:{"text":"Não foi possível obter essas informações"}})],1)],1)],1):_c('div',{staticClass:"text-center mt-1"},[_c('span',{staticClass:"h6"},[_vm._v("Selecione uma Empresa ou Produto.")])])]},proxy:true}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }