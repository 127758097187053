<template>
  <v-card class="mb-5">
    <custom-snackbar
        ref="snackbar"
    />
    <v-card-text class="pt-1">
      <v-snackbar
        v-model="snackbarDownload"
        :color="snackbarDownloadColor"
        fixed
        right
        top
        elevation="24"
      >
        <h4>{{ snackDownloadTitle }}</h4>
        <h5>{{ snackDownloadText }}</h5>
        <v-progress-linear
            indeterminate
            color="white darken-2"
        />
      </v-snackbar>
      <v-toolbar flat class="d-flex flex-row-reverse">
        <v-col class="col-12 col-md-1 my-0 py-0 pr-0">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  class="btn-block"
                  v-on="on"
              >
                <v-icon dark>
                  mdi-printer
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="relatorioPDF()">
                <v-list-item-title>Exportar PDF</v-list-item-title>
              </v-list-item>
              <v-list-item @click="exportFile()">
                <v-list-item-title>Exportar Excel</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-toolbar>
      <v-row class="mt-3">
        <v-col class="col-12 col-md-4 my-0 py-0">
          <EmpresasSelect v-model="empresasFilterValue" @input="fieldsSelected"></EmpresasSelect>
        </v-col>

        <v-col class="col-12 col-md-4 my-0 py-0">
          <ProducoesSelect v-model="producoesFilterValue" :empresa="empresasFilterValue" multiple @input="fieldsSelected"></ProducoesSelect>
        </v-col>

        <v-col class="col-12 col-md-3 my-0 py-0">
          <ProdutoSelect v-model="produtosFilterValue" :empresa="empresasFilterValue" :producao="producoesFilterValue" @input="fieldsSelected" mostra-todos-label :disabled="!empresasFilterValue"></ProdutoSelect>
        </v-col>
        <v-col class="col-12 col-xs-12 col-sm-12 col-lg-1 py-0">
          <v-tooltip
            top
            v-model="pendingSearch"
            color="warning"
            max-width="139px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              max-width="20px"
              outlined
              block
              v-bind="attrs"
              v-on="on"
              color="info"
              class="v-btn--height-fix"
              item-text="nome"
              item-value="id"
              @click="getData"
              :disabled="disabledCampos"
            >
              <v-icon dark>
                mdi-cached
               </v-icon>
              </v-btn>
              </template>
              <span>Busca pendente</span>
            </v-tooltip>
          </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            dense
            sort-by="produto_id"
            :options.sync="options"
            :headers="headers"
            :server-items-length="totalItems"
            :items="data"
            :items-per-page="$dataTableConfig.getItemsPerPage()"
            :footer-props="$dataTableConfig.getFooterProps()"
            :loading-text="$dataTableConfig.getLoadText()"
            :loading="loading"
            :page="page"
            :page-count=" pageCount"
          >
            <template v-slot:progress>
              <v-progress-linear
                absolute
                indeterminate
                color="green"
              ></v-progress-linear>
            </template>

            <template v-slot:[`item.quantidade_agricola`]="{ item }">
              <span class="num_margin">{{ formatNumber(item.quantidade_agricola, 2) }}</span>
            </template>

            <template v-slot:[`item.quantidade_sapiens`]="{ item }">
              <span class="num_margin">{{ formatNumber(item.quantidade_sapiens, 2) }}</span>
            </template>

            <template v-slot:no-data>
              <v-spacer />

              <div v-if="errorLoad">
                <v-row class="justify-center mt-1">
                  <v-col class="col-lg-6">
                    <custom-alert
                        text="Não foi possível obter essas informações"
                    />
                  </v-col>
                </v-row>
              </div>

              <div v-else class="text-center mt-1">
                <span class="h6">Selecione uma Empresa ou Produto.</span>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

    </v-card-text>
  </v-card>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: () => ({
    snackbarDownload: false,
    snackbarDownloadColor: 'success',
    snackDownloadTitle: '',
    snackDownloadText: '',
    producoesDisabled: true,
    produtosDisabled: true,
    pendingSearch: false,
    disabledCampos: true,
    errorLoad: false,
    search: '',
    dialog: false,
    dialogDelete: false,
    dataError: false,
    data: [],

    producoesFilterValue: '',
    empresasFilterValue: '',
    produtosFilterValue: '',

    page: 1,
    pageCount: 0,
    options: {},
    totalItems: 0,
    loading: false,
  }),
  computed: {
    headers () {
      return [
        { text: 'Empresa', value: 'empresa', class: 'custom-table--header' },
        { text: 'Produção', value: 'producao', class: 'custom-table--header' },
        { text: 'Cod. Sapiens', value: 'produto_id', class: 'custom-table--header' },
        { text: 'Descrição', value: 'produto', class: 'custom-table--header' },
        { text: 'Quantidade Agricola', value: 'quantidade_agricola', align: 'right', class: 'custom-table--header' },
        { text: 'Quantidade Sapiens', value: 'quantidade_sapiens', align: 'right', class: 'custom-table--header' },
        { text: 'Unid. Medida', value: 'unidade_medida', class: 'custom-table--header' },

      ]
    },
  },
  watch: {
    empresasFilterValue () {
      this.producoesFilterValue = null
      this.producoesDisabled = false
      this.produtosDisabled = false
      this.disabledCampos = false
    },
    options: {
      handler () {
        this.getData()
      },
    },
  },

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agrícola', route: 'agricola' },
      { title: 'Produtos' },
    ])
  },
  methods: {
    fieldsSelected () {
      this.pendingSearch = true
    },
    getData () {
      this.pendingSearch = false

      if (this.empresasFilterValue !== '' || this.produtosFilterValue !== '') {
        this.loading = true
        this.data = []
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const params = {
          page,
          qtde: itemsPerPage,
          sortBy,
          sortDesc,
          empresa_id: this.empresasFilterValue,
          producao_id: this.producoesFilterValue.filter((v) => v !== ''),
          produto_id: this.produtosFilterValue,
        }

        ApiService.get('/sapiens/produto-estoque', '?' + this.$stringFormat.queryParams(params)).then((res) => {
          const dados = res.data
          this.loading = false
          this.totalItems = dados.total
          this.pageCount = dados.pageCount
          this.data = dados.data
          this.errorLoad = !(dados.lenght > 0)
        })
      }
    },
    relatorioPDF () {
      this.$refs.snackbar.show('Gerando Relatório', 'Seu PDF será gerado e aberto em nova aba para você poder salvar ou imprimir', 'default', -1, true)

      ApiService
        .get('/reports/produtos-estoque', '?' + this.$stringFormat.queryParamsRecursive({
          empresa_id: this.empresasFilterValue,
          producao_id: this.producoesFilterValue.filter((v) => v !== '').join(','),
          produto_id: this.produtosFilterValue,
        }))
        .then((res) => {
          this.$refs.snackbar.show('Relatório Gerado', '', 'success', 3000, false)
          const newWindow = window.open()
          newWindow.document.write(res.data)
        })
        .catch(() => {
          this.$refs.snackbar.show('Relatório Não pode ser gerado', 'Contate a equipe de TI e passe os parâmetros para ser simulado!', 'danger', 10000, false)
        })
    },

    async exportFile () {
      if (this.data.length > 0) {
        const url = '/relatorios/exportar'
        const codDeposito = this.producoesFilterValue.map((v) => parseInt(this.empresasFilterValue + String(v).padStart(2, '0'))).toString()

        const filtros = {
          empresa_id: this.empresasFilterValue > 0 ? this.empresasFilterValue : '0',
          producao_id: this.producoesFilterValue.toString() !== '' ? this.producoesFilterValue.toString() : '0',
          produto_id: this.produtosFilterValue !== '' ? this.produtosFilterValue : '0',
          cod_deposito: codDeposito !== '' ? codDeposito.toString() : '0',
        }

        const params = {
          relatorio: 'Produtos',
          parametros: JSON.stringify(filtros),
        }
        this.snackbarDownload = true
        this.snackbarDownloadColor = 'success'
        this.snackDownloadTitle = 'Gerando o arquivo'
        this.snackDownloadText = 'Aguarde alguns segundos para baixar o arquivo'

        try {
          const res = await ApiService.post(url, params)

          if (res.data.data.arquivo !== '') {
            this.snackDownloadTitle = 'Arquivo gerado com sucesso'
            this.snackDownloadText = ''
            window.open(res.data.data.arquivo, 'Download')
          }

          setTimeout(() => {
            this.snackbarDownload = false
          }, 3000)
        } catch (error) {
          setTimeout(() => {
            this.snackbarDownload = false
          }, 3000)

          this.snackbarDownloadColor = 'red'
          this.snackDownloadTitle = 'Error'
          this.snackDownloadText = 'Não foi possível gerar o arquivo, tente novamente mais tarde.'
          console.error('Erro: ', error)
        }
      } else {
        this.snackbarDownload = true
        this.snackbarDownloadColor = 'red'
        this.snackDownloadTitle = 'Erro'
        this.snackDownloadText = 'Não foram encontrados dados para exportação!'

        setTimeout(() => {
          this.snackbarDownload = false
        }, 3000)
      }
    },

    getDateNow () {
      const now = new Date()
      const dia = ('0' + now.getDate()).slice(-2)
      const mes = ('0' + (now.getMonth() + 1)).slice(-2)
      const ano = now.getFullYear()
      const hora = ('0' + (now.getHours())).slice(-2)
      const minuto = ('0' + (now.getMinutes())).slice(-2)
      const segundos = ('0' + (now.getSeconds())).slice(-2)
      const dataAgora = dia + '/' + mes + '/' + ano + ' ' + hora + ':' + minuto + ':' + segundos
      return dataAgora
    },

    formatNumber (number, decimal) {
      return parseFloat(number).toLocaleString('pt-br', { minimumFractionDigits: decimal })
    },
  },
}
</script>
